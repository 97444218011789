import { type FunctionComponent } from 'react'
import { CuiFlexGroup, CuiInput, CuiPad, CuiPinnedCourses, CuiText } from 'src/cui/components'
import { ReactComponent as CartaLogo } from 'src/images/logos/logo.svg'
import styled from 'styled-components'

import { STATIC_SIDEBAR_PADDING, STATIC_SIDEBAR_WIDTH, TitleComponent } from '../Home/Sidebar'

const SidebarRoot = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: ${STATIC_SIDEBAR_WIDTH};
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  padding-top: ${STATIC_SIDEBAR_PADDING};
  background-color: ${({ theme }) => theme.cuiColors.background};
`

const TermComponents = () => {
  return (
    <CuiFlexGroup alignItems='center' direction='column' gutterSize='m'>
      <CuiPinnedCourses isMajor={true} />
      <CuiPad size='xs' />
    </CuiFlexGroup>
  )
}

const PlannerHeaderWrapper = styled.div`
  background-color: ${({ theme }) => theme.cuiColors.background};
  width: 100%;
`

type PlannerHeaderProps = {
  /**
   *
   * header search value
   */
  searchValue: string

  onSearchValueChange: (value: string) => void
}

const PlannerHeader: FunctionComponent<PlannerHeaderProps> = ({ searchValue, onSearchValueChange }) => {
  return (
    <PlannerHeaderWrapper>
      <CuiPad verticalSize='s' bottomSize='l'>
        <CuiInput iconType='search' iconSide='left' onChange={onSearchValueChange} value={searchValue} />
      </CuiPad>
    </PlannerHeaderWrapper>
  )
}

export const MajorPlannerSidebar: FunctionComponent = () => {
  return (
    <SidebarRoot>
      <TitleComponent>
        <CartaLogo />
      </TitleComponent>
      <CuiPad verticalSize='xs' />

      <CuiFlexGroup alignItems='center' direction='column'>
        <CuiText size='title2'>Courses Taken </CuiText>
      </CuiFlexGroup>

      <CuiPad horizontalSize='m'>
        <PlannerHeader searchValue='' onSearchValueChange={() => {}} />
      </CuiPad>

      <CuiPad horizontalSize='m'>
        <CuiFlexGroup alignItems='stretch' direction='column'>
          <TermComponents />
          <TermComponents />
          <TermComponents />
        </CuiFlexGroup>
      </CuiPad>
    </SidebarRoot>
  )
}
