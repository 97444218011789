import { useNavigate } from '@tanstack/react-location'
import { useQueryClient } from '@tanstack/react-query'
import { type FunctionComponent, type PropsWithChildren, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { type APISchemas } from 'src/api/api'
import { getCourseSchedules } from 'src/api/course'
import {
  getCourseDay,
  getCourseDuration,
  getCourseStart,
  getCourseTitle,
} from 'src/utils/calendarFunctions'
import styled from 'styled-components'

import { CuiFlexGroup } from '../CuiFlexGroup'
import { CuiText } from '../CuiText'

const Cols = [' ', 'Mo', 'Tu', 'We', 'Th', 'Fr']

type CuiCalendarProps = {
  /**
   *
   * current quarter
   *
   *
   */
  currentQuarter: string

  /**
   *
   * end year
   *
   *
   */
  endYear: number

  /**
   *
   * width of each column
   *
   * @default 48
   */
  width?: number

  /**
   *
   * height of each column
   *
   * @default 38
   */
  height: number

  /**
   *
   * number of rows in grid
   *
   *  @default 12
   */
  rows?: number

  /**
   *
   * column titles
   *
   *  @default Cols
   */
  cols?: string[]

  /**
   *
   * list of planned courses
   *
   *
   */
  courses?: string[]
}

type CuiCalendarCourseProps = {
  /**
   *
   * width of each column
   *
   * @default 48
   */
  width?: number

  /**
   *
   * height of each column
   *
   * @default 38
   */
  height: number

  /**
   *
   * margin
   *
   * @default 19
   */
  margin?: number

  /**
   *
   * course title
   *
   *
   */
  courseTitle: string

  /**
   *
   * course duration
   *
   *
   */
  courseDuration: number

  /**
   *
   * on click function
   *
   *
   */
  onClick?: () => void
}

const Cell = styled.div<{ width?: number; height?: number; border?: string }>`
  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;
  border: ${({ border }) => border};
  border-color: ${({ theme }) => theme.cuiColors.darkAccent};
  box-sizing: border-box;
  margin-left: -1px;
  margin-bottom: -1px;
  text-align: center;
`

const Header = styled.div<{ width?: number }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({ width }) => width}px;
  margin-bottom: 10px;
`

const Course = styled.div<{ width?: number; height?: number; margin?: number }>`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;
  background-color: ${({ theme }) => theme.cuiColors.darkAccent};
  color: ${({ theme }) => theme.cuiColors.textOnDarkBackground};
  padding: 2px;
  margin-left: -1px;
  margin-bottom: -1px;
  z-index: 0;
  font-size: 12px;
  line-height: 12px;
  margin-top: ${({ margin }) => margin}px;
  cursor: pointer;
`

const startHour = 8

const CuiCalendarCourse: FunctionComponent<PropsWithChildren<CuiCalendarCourseProps>> = ({
  width,
  height,
  courseTitle,
  courseDuration,
  onClick,
}) => {
  const courseTime = Math.max(height / 2, courseDuration * height)

  return (
    <Course width={width} height={courseTime} onClick={onClick}>
      {courseTitle}
    </Course>
  )
}

function useEnhancedCourseData() {
  const [enhancedCourses, setEnhancedCourses] = useState<any[]>([])
  const data = useSelector((state: any) => state.calendar.calendarData)
  const queryClient = useQueryClient()

  useEffect(() => {
    async function fetchSchedules() {
      const coursesWithSchedules = await Promise.all(
        data.map(async (course: APISchemas['SavedCourse']) => {
          try {
            const scheduleResults = await queryClient.fetchQuery(
              ['/Course/{id}/Schedules', 'get', { id: course.id }],
              () => getCourseSchedules({ id: course.id })
            )

            return { ...course, scheduleData: scheduleResults.entries }
          } catch (error) {
            console.error('Error fetching schedules for course:', course.id, error)

            return { ...course, scheduleData: [] }
          }
        })
      )
      setEnhancedCourses(coursesWithSchedules)
    }

    if (data.length > 0) {
      fetchSchedules()
    }
  }, [data, queryClient])

  return enhancedCourses
}

export const CuiCalendar: FunctionComponent<CuiCalendarProps> = ({
  width,
  height,
  rows,
  cols = Cols,
  currentQuarter,
  endYear,
}) => {
  const rowsArray = useMemo(() => [...Array(rows)], [rows])
  const enhancedData = useEnhancedCourseData()
  const navigate = useNavigate()

  return (
    <CuiFlexGroup direction='column' alignItems='center'>
      <CuiFlexGroup>
        {cols.map((col, index) => (
          <Header key={index} width={width}>
            <CuiText>{col}</CuiText>
          </Header>
        ))}
      </CuiFlexGroup>
      {rowsArray.map((_, rowIndex) => (
        <CuiFlexGroup key={rowIndex}>
          {cols.map((col, colIndex) => (
            <Cell
              key={`${rowIndex}-${colIndex}`}
              width={width}
              height={height}
              border={colIndex === 0 ? 'none' : '1px solid'}
            >
              {enhancedData?.map((course) => {
                const currentDays = getCourseDay({
                  scheduleData: course.scheduleData,
                  currentQuarter,
                  endYear,
                })

                const currentTime = getCourseStart({
                  scheduleData: course.scheduleData,
                  currentQuarter,
                  endYear,
                })
                const isHalfHour = currentTime % 1 !== 0

                const courseDuration = getCourseDuration({
                  scheduleData: course.scheduleData,
                  currentQuarter,
                  endYear,
                })
                const courseTitle = getCourseTitle(course.metaOffering?.courseCode)

                if (currentDays.includes(colIndex) && rowIndex === Math.floor(currentTime)) {
                  return (
                    <CuiCalendarCourse
                      key={course.id}
                      width={width}
                      height={height}
                      margin={isHalfHour ? height / 2 : 0}
                      courseTitle={courseTitle}
                      courseDuration={courseDuration}
                      onClick={() => navigate({ to: `/${course.metaOffering?.id}` })}
                    />
                  )
                }

                return null
              })}
              {colIndex === 0 && rowIndex % 2 === 0 ? (
                <CuiText key={`time-${rowIndex}`} size='caption'>
                  {(startHour + rowIndex) % 12 || 12} {startHour + rowIndex >= 12 ? 'pm' : 'am'} &nbsp;
                </CuiText>
              ) : null}
            </Cell>
          ))}
        </CuiFlexGroup>
      ))}
    </CuiFlexGroup>
  )
}
