import { useMutation, useQuery } from '@tanstack/react-query'

import { api, type APIRoutes, type APISchemas } from './api'

async function createMainPlan(
  body: APIRoutes['/MainPlanner']['post']['requestBody']
): Promise<APISchemas['SavedList']> {
  return api.post('MainPlanner', { json: body }).json<APISchemas['SavedList']>()
}

export function useCreateMainPlan(body: APIRoutes['/MainPlanner']['post']['requestBody']) {
  return useQuery(['MainPlanner', 'post', body], () => createMainPlan(body))
}

function getMainPlan(
  params: APIRoutes['/MainPlanner/{sunetId}']['get']['parameters']['path']
): Promise<APISchemas['SavedList']> {
  return api.get(`MainPlanner/${params.sunetId}`).json<APISchemas['SavedList']>()
}

export function useMainPlan(params: APIRoutes['/MainPlanner/{sunetId}']['get']['parameters']['path']) {
  return useQuery(['MainPlanner/{sunetId}', 'get', params], () => getMainPlan(params))
}

async function editMainPlan(
  params: APIRoutes['/MainPlanner/{sunetId}/Courses']['post']['parameters']['path'],
  body?: APISchemas['SavedListAddCourseRequest']
): Promise<APISchemas['SavedCourse']> {
  try {
    const response = await api
      .post(`MainPlanner/${params.sunetId}/Courses`, { json: body })
      .json<APISchemas['SavedCourse']>()

    return response
  } catch (error: any) {
    if (error.response?.status === 404) {
      console.error('Plan not found', error.response?.data)
      throw new Error('Plan not found')
    }

    throw error
  }
}

export function useEditMainPlan() {
  return useMutation(
    (variables: {
      params: APIRoutes['/MainPlanner/{sunetId}/Courses']['post']['parameters']['path']
      body?: APISchemas['SavedListAddCourseRequest']
    }) => editMainPlan(variables.params, variables.body)
  )
}

async function deleteMainPlan(
  params: APIRoutes['/MainPlanner/{sunetId}/Courses/{savedcourseid}']['delete']['parameters']['path']
): Promise<void> {
  try {
    await api.delete(`MainPlanner/${params.sunetId}/Courses/${params.savedcourseid}`)
  } catch (error: any) {
    if (error.response?.status === 404) {
      console.error('Plan not found', error.response?.data)
      throw new Error('Plan not found')
    }

    throw error
  }
}

export function useDeleteMainPlan() {
  return useMutation(
    (
      params: APIRoutes['/MainPlanner/{sunetId}/Courses/{savedcourseid}']['delete']['parameters']['path']
    ) => deleteMainPlan(params)
  )
}
